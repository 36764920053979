import Video from 'components/Video';
import SectionTitle from 'components/SectionTitle';
import { Trans } from 'next-i18next';
import Link from 'next/link';

const HomeVideo = () => {
  return (
    <div className="home-video">
      <SectionTitle
        header="home.section.howToRent.header"
        description={
          <Trans
            i18nKey="home.section.howToRent.description"
            components={{
              1: (
                <Link
                  className="home-video__link link-type-underline"
                  href="/how-to-rent"
                />
              ),
            }}
          />
        }
      />
      <Video videoId="CGlMAAAPLas" />
    </div>
  );
};

export default HomeVideo;
